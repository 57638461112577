import React from 'react';
import './style.scss';
import { FormattedMessage } from 'react-intl';

export const Loading = () => {
  return (
    <div className="ap-loading__container" aria-live="polite" role="alert">
      <div className="ap-loading__spinner" data-testid="ap-loading-spinner" />
      <div className="ap-loading__text"><FormattedMessage id="loading.text" /></div>
    </div>
  );
};
