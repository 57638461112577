import { combineReducers } from 'redux';
import paymentReducer from './payment.reducer';
import localeReducer from './locale.reducer';
import indicatorReducer from './indicator.reducer';

const RESET_STORE = 'RESET_STORE';

const appReducer = combineReducers({ paymentReducer, localeReducer, indicatorReducer });

// PAYL-13670 - Reset functionality only used to reset store in the tests as we use the real store
// in the unit tests but as they do not get reset after each test, any following tests may get impacted.
// To avoid this, we can reset the store with undefined which will have the reducers return the initial state
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') return appReducer(undefined, action);
  return appReducer(state, action);
};

export function resetStore () {
  return {
    type: RESET_STORE,
  };
}

export default rootReducer;
