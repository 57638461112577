import { calculatePaymentSchedule } from './calculation';

let timer;
const DEFAULT_DURATION = 1200;
const setCancellableTimer = (ms) => new Promise((resolve, reject) => {
  timer && clearTimeout(timer);
  timer = setTimeout(resolve, ms);
});

export async function sendUpdate (amount, locale, duration) {
  duration = duration === undefined ? DEFAULT_DURATION : duration;
  duration && await setCancellableTimer(duration);

  const { paymentSchedules, amountDueToday } = calculatePaymentSchedule(amount, locale);
  return {
    data: {
      amountDueToday,
      paymentSchedules,
      paymentMode: 'PUF',
    },
  };
}

export async function getWidgetConfig () {
  return {
    data: {
      merchant: {
        id: undefined,
        name: undefined,
      },
      consumer: {
        locale: undefined,
        portalApiBaseUrl: undefined,
      },
      order: {
        countryCode: undefined,
        isCrossBorder: false,
      },
    },
  };
}
