import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@afterpaytouch/core';
import { isUndefinedOrEmpty } from '../../utils/string';

export const Money = (props) => {
  const { amount, showCurrency, bold } = props;

  const renderValue = () => {
    const value = amount?.amount;
    if (!isUndefinedOrEmpty(value)) {
      if (showCurrency) {
        return `${amount?.symbol}${value} ${amount?.currency}`;
      } else {
        return `${amount?.symbol}${value}`;
      }
    }
    return '';
  };

  return (
    <Text bold={bold} renderAs="span">
      <span>{renderValue()}</span>
    </Text>
  );
};

Money.propTypes = {
  amount: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    symbol: PropTypes.string,
  }),
  bold: PropTypes.bool,
  showCurrency: PropTypes.bool,
};

Money.defaultProps = {
  showCurrency: false,
  bold: false,
};
