import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { Text } from '@afterpaytouch/core';

import { ReactComponent as PaymentScheduleIcon } from '../../assets/icons/payment-schedule.svg';
import { DueToday } from '../../components/due-today';
import { HarveyBall } from '../../components/harvey-ball';
import { Logo } from '../../components/logo';
import { throttle, getFormattedDate } from '../../utils/utils';
import { ErrorContainer } from '../../components/error-container';
import { Loading } from '../../components/loading';
import { BubbleMessage } from '../../components/bubble-message';
import { updateAmount, updateIsCrossBorder, updatePaymentInfo, updateToken } from '../../redux/payment.reducer';
import { setIsLoading, setHasError } from '../../redux/indicator.reducer';
import { updateLocale } from '../../redux/locale.reducer';
import api from '../../api';
import { setPortalApiBaseUrl } from '../../api/remote';
import { CONSUMER_CUSTOM_ERROR, US_LOCALES } from '../../utils/constants';
import { TextWithLink } from '../../components/text-with-link';
import * as Analytics from '../../analytics';
import AMPLITUDE_EVENTS from '../../analytics/events';
import { isSupportedLocale } from '../../locale';

const BREAKPOINTS = {
  EXTRA_LARGE: 650,
  LARGE: 500,
  MEDIUM: 375,
  SMALL: 300,
};

const PaymentSchedule = () => {
  const containerRef = useRef(null);
  const store = useStore();
  const dispatch = useDispatch();
  const [widgetSize, setWidgetSize] = useState('lg');
  const [showBorder, setShowBorder] = useState(true);
  const [showHeading, setShowHeading] = useState(true);
  const [showLogo, setShowLogo] = useState(true);
  const { amountDueToday, paymentSchedules, amount, paymentMode, token, isCrossBorder } = useSelector((state) => state.paymentReducer);
  const { errorCode, errorInfo } = useSelector((state) => state.indicatorReducer.error);
  const { brandName, locale } = useSelector(state => state.localeReducer);
  const { isLoading, hasError } = useSelector(state => state.indicatorReducer);
  const intl = useIntl();

  const getWidgetSize = (containerWidth) => {
    if (containerWidth > BREAKPOINTS.EXTRA_LARGE) {
      return 'xl';
    } else if (containerWidth > BREAKPOINTS.LARGE) {
      return 'lg';
    } else if (containerWidth > BREAKPOINTS.MEDIUM) {
      return 'md';
    } else {
      return 'sm';
    }
  };

  const onWindowResize = () => {
    const container = containerRef.current;
    container && setWidgetSize(getWidgetSize(container.offsetWidth));
  };

  const handleOnError = (error) => {
    const errorMessage = Object.values(CONSUMER_CUSTOM_ERROR).includes(error.errorCode) ? 'Consumer has been declined.' : error;
    const data = {
      error: errorMessage,
      amountDueToday: undefined,
      paymentScheduleChecksum: undefined,
      isValid: false,
    };

    dispatch(setHasError(true, error));
    console.error(`${brandName}: ${errorMessage.message || errorMessage}`);
    return data;
  };

  const calcOrderAmountByPaymentSchedules = (paymentSchedules) => {
    return paymentSchedules.reduce((total, curr) => total + parseFloat(curr.amount.amount), 0);
  };

  const handleAmplitudeEventOnMounted = async (payload) => {
    const { token, paymentMode, amountDueToday, paymentSchedules, merchantId, merchantName, countryCode, isCrossBorder } = payload;
    if (!token) return;

    const eventProperties = {
      transactionToken: token,
      orderAmount: {
        amount: calcOrderAmountByPaymentSchedules(paymentSchedules).toFixed(2),
        currency: amountDueToday.currency,
      },
      isCrossBorder,
    };

    try {
      eventProperties.merchantId = merchantId;
      eventProperties.merchantName = merchantName;
      eventProperties.countryCode = countryCode;

      Analytics.sendAmplitudeEvent(AMPLITUDE_EVENTS.VIEWED_LOADED_CHECKOUT_WIDGET,
        Object.assign({}, eventProperties, {
          paymentMode,
          dueTodayAmount: amountDueToday,
        }),
      );
    } catch (err) {
      // PAYL-14352: If fetch the checkout details failed, still send a successful event to Amplitude without merchant and countryCode info
      Analytics.sendAmplitudeEvent(AMPLITUDE_EVENTS.VIEWED_LOADED_CHECKOUT_WIDGET, eventProperties);
    }

    Analytics.setEnduringEventProperties(eventProperties);
  };

  const handleAmplitudeEventOnUpdate = (payload) => {
    const { token, newAmount, amountDueToday, paymentMode } = payload;
    if (!token) return;

    let enduringEventProperties = Analytics.setEnduringEventProperties();
    const prevOrderAmount = parseFloat(enduringEventProperties.orderAmount.amount);
    const currOrderAmount = parseFloat(newAmount.amount);
    enduringEventProperties.orderAmount = {
      amount: currOrderAmount.toFixed(2),
      currency: amountDueToday.currency,
    };
    Analytics.setEnduringEventProperties(enduringEventProperties);

    // PAYL-14352: Don't save these properties
    enduringEventProperties = Object.assign({}, enduringEventProperties, {
      paymentMode,
      dueTodayAmount: amountDueToday,
      orderAmountChange: {
        type: currOrderAmount === prevOrderAmount ? 'Equality' : currOrderAmount > prevOrderAmount ? 'Increase' : 'Decrease',
        amount: Math.abs(currOrderAmount - prevOrderAmount).toFixed(2),
      },
    });

    Analytics.sendAmplitudeEvent(AMPLITUDE_EVENTS.VIEWED_UPDATED_CHECKOUT_WIDGET, enduringEventProperties);
  };

  const handleCrossBorderTrade = (isCrossBorder, consumerLocale, consumerPortalApiBaseUrl) => {
    if (!isCrossBorder) {
      return;
    }

    dispatch(updateIsCrossBorder(isCrossBorder));
    setPortalApiBaseUrl(consumerPortalApiBaseUrl);

    if (isSupportedLocale(consumerLocale)) {
      dispatch(updateLocale(consumerLocale));
    }
  };

  const handleInit = async (initAmount, onWidgetReady, onWidgetError) => {
    let data;
    const { token } = store.getState().paymentReducer;
    const { locale } = store.getState().localeReducer;

    try {
      const { data: widgetConfig } = await api.retrieveWidgetConfig(token);
      const { id: merchantId, name: merchantName } = widgetConfig.merchant;
      const { locale: consumerLocale, portalApiBaseUrl: consumerPortalApiBaseUrl } = widgetConfig.consumer;
      const { countryCode, isCrossBorder } = widgetConfig.order;

      handleCrossBorderTrade(isCrossBorder, consumerLocale, consumerPortalApiBaseUrl);

      const initCheckoutResponse = await api.retrieveInitCheckout(initAmount, token, locale);
      let { amountDueToday, paymentSchedules, paymentScheduleChecksum, paymentMode, merchantAmount, consumerAmount, exchangeRate } = initCheckoutResponse.data;

      data = {
        error: undefined,
        amountDueToday,
        paymentScheduleChecksum,
        isValid: true,
      };

      if (token) {
        paymentSchedules = paymentSchedules.map(paymentSchedule => Object.assign({}, paymentSchedule, { installmentDueDate: getFormattedDate(paymentSchedule.installmentDueDate, locale) }));
      } else {
        dispatch(updateAmount(initAmount));
      }

      dispatch(updatePaymentInfo({ paymentSchedules, amountDueToday, paymentMode, merchantAmount, consumerAmount, exchangeRate }));
      dispatch(setIsLoading(false));
      onWidgetReady && onWidgetReady(data);

      handleAmplitudeEventOnMounted({ token, paymentSchedules, amountDueToday, paymentMode, merchantId, merchantName, countryCode, isCrossBorder });
    } catch (err) {
      data = handleOnError(err);
      onWidgetError && onWidgetError(data);

      token && Analytics.sendAmplitudeEvent(AMPLITUDE_EVENTS.VIEWED_CHECKOUT_WIDGET_LOADING_ERROR, {
        error: err.errorCode ? { message: err.message, errorCode: err.errorCode } : err,
      });
    }
  };

  const handleAmountProp = async (newAmount, onWidgetChange, onWidgetError) => {
    if (amount.currency === newAmount.currency && amount.amount === newAmount.amount) {
      return;
    }

    dispatch(setIsLoading(true));
    let data;
    const { token } = store.getState().paymentReducer;
    const { locale } = store.getState().localeReducer;

    try {
      const res = await api.updateCheckout(newAmount, token, locale);
      let { amountDueToday, paymentSchedules, paymentScheduleChecksum, paymentMode, merchantAmount, consumerAmount, exchangeRate } = res.data;

      data = {
        error: undefined,
        amountDueToday,
        paymentScheduleChecksum,
        isValid: true,
      };

      if (token) {
        paymentSchedules = paymentSchedules.map(paymentSchedule => Object.assign({}, paymentSchedule, { installmentDueDate: getFormattedDate(paymentSchedule.installmentDueDate, locale) }));
      }

      dispatch(updatePaymentInfo({ paymentSchedules, amountDueToday, paymentMode, merchantAmount, consumerAmount, exchangeRate }));
      dispatch(updateAmount(newAmount));
      dispatch(setIsLoading(false));

      handleAmplitudeEventOnUpdate({ token, newAmount, amountDueToday, paymentMode });
    } catch (err) {
      data = handleOnError(err);
      onWidgetError && onWidgetError(data);

      token && Analytics.sendAmplitudeEvent(AMPLITUDE_EVENTS.VIEWED_DECLINED_CHECKOUT_WIDGET, {
        orderAmount: newAmount,
        error: err.errorCode ? { message: err.message, errorCode: err.errorCode } : err,
      });
    }

    onWidgetChange && onWidgetChange(data);
  };

  const onWindowResizeWithThrottled = throttle(onWindowResize);

  useEffect(() => {
    window.addEventListener('resize', onWindowResizeWithThrottled);
    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResizeWithThrottled);
    };
  }, [showBorder, showHeading, showLogo]);

  useEffect(() => {
    if (!('xprops' in window)) return;

    const { token, locale, onWidgetReady, onWidgetChange, onWidgetError, onProps, style, amount, portalApiBaseUrl, amplitudeClientID } = window.xprops;
    Analytics.initAmplitude(amplitudeClientID);
    setPortalApiBaseUrl(portalApiBaseUrl);
    dispatch(updateToken(token));
    // if cross-border trade, this will be overridden with consumer locale during a subsequent step
    dispatch(updateLocale(locale));

    if (style) {
      const { border, heading, logo } = style;
      setShowBorder(border === false ? border : true);
      setShowHeading(heading === false ? heading : true);
      setShowLogo(logo === false ? logo : true);
    }

    handleInit(amount, onWidgetReady, onWidgetError);

    onProps(({ amount }) => {
      amount && handleAmountProp(amount, onWidgetChange, onWidgetError);
    });
  }, []);

  const bodyCls = classNames('ap-payment-schedule__body', {
    'ap-payment-schedule__body--border': showBorder,
  });

  const bodyContentCls = classNames('ap-payment-schedule__body-content', {
    'ap-payment-schedule__body-content--hidden': isLoading || hasError,
  });

  const harveyBallBlockCls = classNames(bodyContentCls, {
    'ap-payment-schedule__body-content--sm': showBorder && widgetSize === 'sm',
  });

  return (
    <div
      className="ap-payment-schedule"
      ref={containerRef}
    >
      <div className={bodyCls} data-testid="ap-widget-border">
        {isLoading && <Loading />}
        {hasError && <ErrorContainer brandName={brandName} errorCode={errorCode} errorInfo={errorInfo} size={widgetSize} hasLogo={showLogo} hasBorder={showBorder} />}
        {showLogo && <div className="ap-payment-schedule__body-content"><Logo brandName={brandName} /></div>}
        {showHeading && (
          <div className={bodyContentCls} data-testid="heading-block">
            <div className="ap-payment-schedule__icon-container">
              {widgetSize !== 'sm' && <PaymentScheduleIcon className="ap-payment-schedule__icon" />}
              <Text renderAs="span"><FormattedMessage id="paymentSchedule.subHeading" /></Text>
            </div>
          </div>
        )}
        <div className={harveyBallBlockCls} data-testid="harvey-ball-block" id="harvey-ball-block">
          <HarveyBall paymentMode={paymentMode} paymentSchedules={paymentSchedules} size={widgetSize} />
          {token && <BubbleMessage size={widgetSize} />}
        </div>
        <div className={bodyContentCls} data-testid="due-today-block">
          <hr className="ap-payment-schedule__divider" />
          <DueToday amountDueToday={amountDueToday} size={widgetSize} isCrossBorder={isCrossBorder} />
        </div>
      </div>
      {!US_LOCALES.includes(locale) && token &&
        <>
          {(!showBorder) && <hr data-testid="ap-no-border-tc-divider" className="ap-payment-schedule__divider" />}
          <div className="ap-payment-schedule__footer">
            <div className="ap-typography--muted">
              <TextWithLink
                prefixText={intl.formatMessage({ id: 'termsAndConditions.prefixText' }, { brandName })}
                link={intl.formatMessage({ id: 'termsAndConditions.link' })}
                linkText={intl.formatMessage({ id: 'termsAndConditions.linkText' })}
              />
            </div>
          </div>
        </>}
    </div>
  );
};

export default PaymentSchedule;
