import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';
import { TextWithLink } from '../text-with-link';

export const ErrorMessage = (props) => {
  const { header, bodyText, instructions, isLongBodyText, hasLogo, hasBorder, size } = props;
  const cls = classNames('ap-error', {
    'ap-error__padding--sm': isLongBodyText,
    [`ap-error--${size}`]: size === 'md' || size === 'sm',
  });

  const errorContainerCls = classNames('ap-error__container', {
    'ap-error__container-full-width': size === 'sm',
    'ap-error__container-max-width': size !== 'sm',
  });

  return (
    <div
      className={cls}
      style={{ top: (hasLogo && (size === 'md' || size === 'sm')) ? (hasBorder ? 53 : 30) : 0 }}
    >
      <div className={errorContainerCls}>
        <div className="ap-error__title">{header}</div>
        <div className="ap-error__text">{bodyText}</div>
        {instructions &&
          <>
            <br />
            <div className="ap-error__text">
              <TextWithLink link={instructions.link} color="error" linkText={instructions.linkText} suffixText={instructions.suffixText} prefixText={instructions.prefixText} />
            </div>
          </>}
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  header: PropTypes.string,
  bodyText: PropTypes.string,
  instructions: PropTypes.shape({
    link: PropTypes.string,
    linkText: PropTypes.string,
    suffixText: PropTypes.string,
    prefixText: PropTypes.string,
  }),
  isLongBodyText: PropTypes.bool,
  hasLogo: PropTypes.bool,
  hasBorder: PropTypes.bool,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
};

ErrorMessage.defaultProps = {
  header: "Well that didn't work...",
  bodyText: "Sorry, something's gone wrong here so you may need to refresh the page or exit the checkout and try again.",
  instructions: null,
  isLongBodyText: false,
  hasLogo: true,
  hasBorder: true,
};
