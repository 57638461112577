import { useRef, useEffect } from 'react';

/**
 * reference: https://usehooks.com/usePrevious/
 */
export function usePrevious (value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
