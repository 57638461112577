const UPDATE_AMOUNT = 'UPDATE_AMOUNT';
const UPDATE_IS_CROSS_BORDER = 'UPDATE_IS_CROSS_BORDER';
const UPDATE_PAYMENT_INFO = 'UPDATE_PAYMENT_INFO';
const UPDATE_TOKEN = 'UPDATE_TOKEN';

const initState = {
  token: '',
  amount: { amount: '0.00', currency: 'GBP' },
  amountDueToday: {},
  paymentSchedules: new Array(4).fill({
    amount: { amount: '0.00', currency: 'GBP', symbol: '£' },
    installmentDueDate: '',
  }),
  paymentMode: undefined,
  isCrossBorder: false,
  merchantAmount: { amount: '0.00', currency: 'GBP', symbol: '£' },
  consumerAmount: { amount: '0.00', currency: 'GBP', symbol: '£' },
  exchangeRate: undefined,
};

export default function paymentReducer (state = initState, action = {}) {
  switch (action.type) {
    case UPDATE_AMOUNT:
      return {
        ...state,
        amount: action.amount,
      };
    case UPDATE_IS_CROSS_BORDER:
      return {
        ...state,
        isCrossBorder: action.isCrossBorder,
      };
    case UPDATE_PAYMENT_INFO: {
      const { amountDueToday, paymentSchedules, paymentMode, consumerAmount, merchantAmount, exchangeRate } = action;
      return {
        ...state,
        amountDueToday,
        paymentSchedules,
        paymentMode,
        consumerAmount,
        merchantAmount,
        exchangeRate,
      };
    }
    case UPDATE_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    default:
      return state;
  }
}

export function updateAmount (amount) {
  return {
    amount,
    type: UPDATE_AMOUNT,
  };
}

export function updateIsCrossBorder (isCrossBorder) {
  return {
    isCrossBorder,
    type: UPDATE_IS_CROSS_BORDER,
  };
}

export function updatePaymentInfo (paymentInfo) {
  return {
    ...paymentInfo,
    type: UPDATE_PAYMENT_INFO,
  };
}

export function updateToken (token) {
  return {
    token,
    type: UPDATE_TOKEN,
  };
}
