import amplitude from 'amplitude-js';

import * as Storage from '../utils/storage';

export const initAmplitude = function (apiKey) {
  amplitude.getInstance().init(apiKey, undefined, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    unsetParamsReferrerOnNewSession: true,
  });
};

export const setAmplitudeUserDevice = function (deviceId) {
  amplitude.getInstance().setDeviceId(deviceId);
};

export const setAmplitudeUserId = function (userId) {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = function (properties) {
  amplitude.getInstance().setUserProperties(properties);
};

export const setEnduringEventProperties = function (enduringEventProperties) {
  return Storage.setSessionStorageItem('enduringEventProperties', enduringEventProperties);
};

export const sendAmplitudeEvent = function (eventName, eventProperties) {
  if (!eventName) return;
  if (!eventProperties) {
    eventProperties = {};
  }

  const enduringEventProperties = setEnduringEventProperties();
  eventProperties = { category: 'Checkout Widget', ...enduringEventProperties, ...eventProperties };

  amplitude.getInstance().logEvent(eventName, eventProperties);
};
