import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Clearpay } from '../../assets/icons/clearpay.svg';
import { ReactComponent as Afterpay } from '../../assets/icons/afterpay.svg';

export const Logo = (props) => (
  props.brandName === 'Clearpay' ? <Clearpay title="Clearpay" data-testid="ap-logo" /> : <Afterpay title="Afterpay" data-testid="ap-logo" />
);

Logo.propTypes = {
  brandName: PropTypes.string,
};
