import enUSMessages from './en-US.json';
import enGBMessages from './en-GB.json';
import enAUMessages from './en-AU.json';
import enNZMessages from './en-NZ.json';
import enCAMessages from './en-CA.json';
import frCAMessages from './fr-CA.json';

export const messages = {
  'en-GB': enGBMessages,
  'en-US': enUSMessages,
  'en-AU': enAUMessages,
  'en-NZ': enNZMessages,
  'en-CA': enCAMessages,
  'fr-CA': frCAMessages,
};

export const isSupportedLocale = (locale) => locale in messages;
