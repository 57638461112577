import { getCurrencySymbol } from '../utils/utils';
import { createIntl } from 'react-intl';
import { messages } from '../locale';

function getInstallmentStrings (amount) {
  const roundToCents = value => Math.round(value * 100) / 100;

  const orderTotal = parseFloat(amount.amount);
  const installment = roundToCents(orderTotal / 4);
  const finalInstallment = roundToCents(orderTotal - installment * 3);

  return new Array(4).fill(installment.toFixed(2)).fill(finalInstallment.toFixed(2), -1);
}

export function calculatePaymentSchedule (amount, locale) {
  const installmentStrings = getInstallmentStrings(amount);
  const symbol = getCurrencySymbol(amount.currency);
  const intl = createIntl({
    locale: locale,
    messages: messages[locale],
  });

  const dates = [
    intl.formatMessage({ id: 'harveyBall.today' }),
    intl.formatMessage({ id: 'harveyBall.inWeeks' }, { intervalNumber: 2 }),
    intl.formatMessage({ id: 'harveyBall.inWeeks' }, { intervalNumber: 4 }),
    intl.formatMessage({ id: 'harveyBall.inWeeks' }, { intervalNumber: 6 }),
  ];

  const paymentSchedules = new Array(4).fill(0).map((_, idx) => ({
    amount: { symbol, amount: installmentStrings[idx], currency: amount.currency },
    installmentDueDate: dates[idx],
  }));
  const amountDueToday = paymentSchedules[0].amount;

  return { paymentSchedules, amountDueToday };
}
