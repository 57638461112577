import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag, Heading, Text } from '@afterpaytouch/core';
import PropTypes from 'prop-types';
import './styles.scss';

export const DueToday = (props) => {
  const { amountDueToday, size, isCrossBorder } = props;
  const { amount, symbol, currency } = amountDueToday;

  return (
    <div className="ap-widget-due-today">
      <div className="ap-widget-due-today-content"><Text size={size === 'sm' ? 'S' : 'M'}><FormattedMessage id="dueToday" /></Text></div>

      <div className="ap-widget-due-today-content" data-testid="ap-amount-due-today">{isCrossBorder && <Tag.Gray>{currency}</Tag.Gray>} <Heading size={size === 'sm' ? 'S' : 'M'}>{symbol}{amount}</Heading></div>
    </div>
  );
};

DueToday.propTypes = {
  amountDueToday: PropTypes.shape({
    amount: PropTypes.string,
    currency: PropTypes.string,
    symbol: PropTypes.string,
    format: PropTypes.func,
  }),
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
  isCrossBorder: PropTypes.bool,
};

DueToday.defaultProps = {
  amountDueToday: { amount: '0.00', currency: 'AUD', symbol: '$' },
  size: 'lg',
  isCrossBorder: false,
};
