export const US_LOCALES = ['en-US', 'en-CA', 'fr-CA'];
export const EU_LOCALES = ['en-GB'];
export const ANZ_LOCALES = ['en-AU', 'en-NZ'];
export const CONSUMER_CUSTOM_ERROR = {
  AMOUNT_TOO_HIGH: 'amount_too_high',
  ORDER_AMOUNT_TOO_HIGH: 'order_amount_too_high',
  UNABLE_TO_PROCESS_ORDER: 'unable_to_process_order',
};
export const GENERAL_ERROR = 'general_error';
export const PAYMENT_MODE = {
  PUF: 'PUF',
  NPUF: 'NPUF',
  VPUF: 'VPUF',
  LPUF: 'LPUF',
};
