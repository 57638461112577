const transientStorage = {};

export const isSupported = function () {
  let isSupported = false;
  try {
    // Safari won't allow writing to session or local storage while in private browsing mode.
    // This allows us to determine if it's supported or not and act accordingly
    window.sessionStorage.setItem('sessionStorage', '1');
    window.sessionStorage.removeItem('sessionStorage');
    // window.localStorage.setItem("localStorage", "1");
    // window.localStorage.removeItem("localStorage");
    isSupported = true;
  } catch (err) {
    isSupported = false;
  }
  return isSupported;
};

export const setTransientStorageItem = function (itemName, itemValue) {
  if (itemValue !== undefined) {
    transientStorage[itemName] = itemValue;
  }
  return transientStorage[itemName];
};

export const setSessionStorageItem = function (itemName, itemValue) {
  // Tries to store and retrieve from session storage first
  // If that isn't supported transient storage is used instead
  let stored;
  if (isSupported()) {
    if (itemValue !== undefined) {
      window.sessionStorage.setItem(itemName, JSON.stringify(itemValue));
    }
    stored = JSON.parse(window.sessionStorage.getItem(itemName));
  } else {
    stored = setTransientStorageItem(itemName, itemValue);
  }
  return stored;
};

export const getSessionStorageItem = function (itemName) {
  return setSessionStorageItem(itemName);
};
