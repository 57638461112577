/**
 * Get currency symbol by currency ISO code
 * @param {string} currency
 */
export function getCurrencySymbol (currency) {
  switch (currency) {
    case 'GBP':
      return '£';
    default:
      return '$';
  }
}

/**
 * Format a date object like pattern '01 Nov'.
 * @param {String} dateString: pattern: '2009-09-01'
 */
export function getFormattedDate (dateString, locale = 'en-GB') {
  const dt = new Date(dateString);
  return new Intl.DateTimeFormat(locale, { month: 'short', day: '2-digit' }).format(dt);
}

/**
 * Group multiple sequential calls in a single one.
 * @param {Function} fn: callback function
 * @param {number} wait
 */
export function debounce (fn, wait = 500) {
  let timeout;

  return function () {
    const self = this;
    const args = arguments;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(function () {
      fn.apply(self, args);
    }, wait);
  };
}

/**
 * Allow the function execute only once in a certain time.
 * @param {Function} fn: callback function
 * @param {number} wait
 */
export function throttle (fn, wait = 500) {
  let timeout;

  return function () {
    const self = this;
    const args = arguments;
    if (!timeout) {
      timeout = setTimeout(function () {
        timeout = null;
        fn.apply(self, args);
      }, wait);
    }
  };
}

/**
 * Get currency symbol by currency ISO code
 * @param {any} value
 */
export function valueIsNotNullOrUndefined (value) {
  return value !== null && value !== undefined;
}
