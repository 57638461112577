import axios from 'axios';

const instance = axios.create({
  timeout: 5000,
});
instance.interceptors.request.use(config => config, err => {
  return Promise.reject(err.response.data);
});
instance.interceptors.response.use(response => response, err => {
  return Promise.reject(err.response.data);
});

export function setPortalApiBaseUrl (url) {
  instance.defaults.baseURL = url;
}

export function getPortalApiBaseUrl () {
  return instance.defaults.baseURL;
}

export function initCheckout (token) {
  return instance.get(`/portal/consumers/widgets/checkout/${token}`);
}

export function updateCheckout (token, amount) {
  return instance.post(`/portal/consumers/widgets/checkout/${token}`, { amount });
}

export function getWidgetConfig (token) {
  return instance.get(`/portal/consumers/widgets/checkout/${token}/config`);
}
