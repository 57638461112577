const UPDATE_LOCALE = 'UPDATE_LOCALE';

const initState = {
  brandName: 'Afterpay',
  locale: 'en-AU',
};

export default function localeReducer (state = initState, action = {}) {
  switch (action.type) {
    case UPDATE_LOCALE:
      return {
        ...state,
        locale: action.locale,
        brandName: action.locale === 'en-GB' ? 'Clearpay' : 'Afterpay',
      };
    default:
      return state;
  }
};

export function updateLocale (locale) {
  return {
    locale,
    type: UPDATE_LOCALE,
  };
}
