import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { messages } from '../locale';

export const LocaleProvider = (props) => {
  const { locale } = useSelector((state) => state.localeReducer);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};
