import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

export const TextWithLink = (props) => {
  const { prefixText, link, linkText, suffixText, color } = props;
  const cls = classNames({
    'ap-link_color--default': !color,
    'ap-link_color--error': color === 'error',
  });
  return (
    <>
      {prefixText && <>{prefixText} </>}<a className={cls} href={link} target="_blank" rel="noreferrer noopener">{linkText}</a>{suffixText && <> {suffixText}</>}
    </>
  );
};

TextWithLink.propTypes = {
  prefixText: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  suffixText: PropTypes.string,
  color: PropTypes.oneOf(['error']),
};

TextWithLink.defaultProps = {
  prefixText: '',
  link: '',
  linkText: '',
  suffixText: '',
  color: null,
};
