import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { Text } from '@afterpaytouch/core';
import { useSelector } from 'react-redux';
import { Money } from '../money';
import { FormattedMessage } from 'react-intl';

const BALL_SIZE = {
  xl: 64,
  lg: 48,
  md: 40,
  sm: 32,
};

const BALL_MAX_WIDTH = {
  xl: 500,
  lg: 375,
  md: 330,
  sm: 300,
};

const BALL_FONT_SIZE = {
  xl: 16,
  lg: 12,
  md: 12,
  sm: 10,
};

const Circle = ({ percentage, width, strokeWidth = 4 }) => {
  const relativeStrokeWidth = () => {
    return ((strokeWidth / width) * 100).toFixed(1);
  };

  const trackPath = () => {
    const radius = 50 - parseFloat(relativeStrokeWidth()) / 2;
    return `M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${radius * 2} a ${radius} ${radius} 0 1 1 0 -${radius * 2}`;
  };

  const _perimeter = () => {
    const radius = 50 - parseFloat(relativeStrokeWidth()) / 2;
    return 2 * Math.PI * radius;
  };

  const circlePathStyle = () => {
    const perimeter = _perimeter();
    const strokeDash = 1 - percentage / 100;
    return {
      strokeDasharray: `${perimeter}px, ${perimeter}px`,
      strokeDashoffset: strokeDash * perimeter + 'px',
    };
  };

  return (
    <svg viewBox="0 0 100 100" width={width} height={width}>
      <path
        className="ap-harvey-ball__ball-bg"
        d={trackPath()}
        strokeWidth={relativeStrokeWidth()}
      />
      <path
        className="ap-harvey-ball__ball-path"
        d={trackPath()}
        strokeLinecap="round"
        strokeWidth={relativeStrokeWidth()}
        style={circlePathStyle()}
      />
    </svg>
  );
};

export const HarveyBall = (props) => {
  const { paymentSchedules, size, paymentMode } = props;
  const isCrossBorder = useSelector((state) => state.paymentReducer.isCrossBorder);

  const ballSize = BALL_SIZE[size];
  return (
    <div className="ap-harvey-ball" style={{ maxWidth: BALL_MAX_WIDTH[size] }}>
      {paymentSchedules.map((paymentSchedule, idx) => {
        const { amount, installmentDueDate } = paymentSchedule;
        return (
          <div key={idx} className="ap-harvey-ball__item">
            <div className="ap-harvey-ball__ball-container" style={{ width: ballSize, height: ballSize }}>
              <Circle percentage={25 * (idx + 1)} width={ballSize - 6} strokeWidth={size === 'xl' ? 6 : 4} />
              <label className="ap-harvey-ball__inner-text" style={{ fontSize: BALL_FONT_SIZE[size] }}>{idx + 1}</label>
            </div>
            <div className="ap-harvey-ball__installment">
              <div className="ap-harvey-ball__installment-amount"><Money bold={idx === 0} showCurrency={isCrossBorder} amount={amount} /></div>
              <div className="ap-harvey-ball__installment-date">
                <Text bold={idx === 0} size={size === 'sm' ? 'XS' : 'S'}>{idx === 0 && paymentMode !== 'NPUF' ? <FormattedMessage id="harveyBall.today" /> : installmentDueDate}</Text>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

HarveyBall.propTypes = {
  paymentSchedules: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.shape({
        amount: PropTypes.string,
        currency: PropTypes.string,
        symbol: PropTypes.string,
      }),
      installmentDueDate: PropTypes.string,
    }),
  ),
  paymentMode: PropTypes.string,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
};

HarveyBall.defaultProps = {
  paymentSchedules: [
    { amount: { amount: '0.00', currency: 'AUD', symbol: '$' }, installmentDueDate: '' },
    { amount: { amount: '0.00', currency: 'AUD', symbol: '$' }, installmentDueDate: '' },
    { amount: { amount: '0.00', currency: 'AUD', symbol: '$' }, installmentDueDate: '' },
    { amount: { amount: '0.00', currency: 'AUD', symbol: '$' }, installmentDueDate: '' },
  ],
  paymentMode: 'PUF',
  size: 'lg',
};
