import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import './index.scss';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import PaymentSchedule from './containers/payment-schedule';
import { store } from './redux/store';
import { LocaleProvider } from './provider/locale-provider';

const lazilyImportZoid = async (zoidVersion) => {
  switch (zoidVersion) {
    case '9.0.54': return await import('zoid_9-0-54');
    case '9.0.85': return await import('zoid_9-0-85');
  }
};

(async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const zoidVersion = searchParams.get('zoid');
  const zoid = await lazilyImportZoid(zoidVersion);

  ReactDOM.render(
    <ReduxProvider store={store}>
      <LocaleProvider>
        <PaymentSchedule />
      </LocaleProvider>
    </ReduxProvider>,
    document.getElementById('root'),
    () => {
      zoid.create({
        tag: 'checkout-widget',
        autoResize: {
          width: false,
          height: true,
          element: '.ap-payment-schedule',
        },
      });
    },
  );
})();
