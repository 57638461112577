import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '../error-message';
import { CONSUMER_CUSTOM_ERROR, GENERAL_ERROR } from '../../utils/constants';
import { useIntl } from 'react-intl';

export const ErrorContainer = (props) => {
  const { size, errorInfo, errorCode, brandName, hasLogo, hasBorder } = props;
  const intl = useIntl();

  const message = {
    [CONSUMER_CUSTOM_ERROR.AMOUNT_TOO_HIGH]: {
      header: intl.formatMessage({ id: 'errorMessage.amountTooHigh.header' }),
      body: intl.formatMessage({ id: 'errorMessage.amountTooHigh.body' }, { max_otb_amount: errorInfo.max_otb_amount }),
      instructions: {
        link: intl.formatMessage({ id: 'errorMessage.amountTooHigh.instructions.link' }),
        linkText: intl.formatMessage({ id: 'errorMessage.amountTooHigh.instructions.linkText' }),
        suffixText: intl.formatMessage({ id: 'errorMessage.amountTooHigh.instructions.suffixText' }),
      },
    },
    [CONSUMER_CUSTOM_ERROR.ORDER_AMOUNT_TOO_HIGH]: {
      header: intl.formatMessage({ id: 'errorMessage.orderAmountTooHigh.header' }),
      body: intl.formatMessage({ id: 'errorMessage.orderAmountTooHigh.body' }),
    },
    [CONSUMER_CUSTOM_ERROR.UNABLE_TO_PROCESS_ORDER]: {
      header: intl.formatMessage({ id: 'errorMessage.unableToProcessOrder.header' }),
      body: intl.formatMessage({ id: 'errorMessage.unableToProcessOrder.body' }, { brandName }),
    },
    [GENERAL_ERROR]: {
      header: intl.formatMessage({ id: 'errorMessage.general.header' }),
      body: intl.formatMessage({ id: 'errorMessage.general.body' }),
    },
  };

  const { header, body, instructions } = Object.values(CONSUMER_CUSTOM_ERROR).includes(errorCode) ? message[errorCode] : message[GENERAL_ERROR];
  const isLongBodyText = size === 'sm' && body.length > 110;

  return (
    <ErrorMessage header={header} bodyText={body} instructions={instructions} isLongBodyText={isLongBodyText} hasLogo={hasLogo} hasBorder={hasBorder} size={size} />
  );
};

ErrorContainer.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
  errorCode: PropTypes.string,
  errorInfo: PropTypes.object,
  brandName: PropTypes.string,
  hasLogo: PropTypes.bool,
  hasBorder: PropTypes.bool,
};

ErrorContainer.defaultProps = {
  size: 'lg',
  errorCode: '',
  errorInfo: {
    max_otb_amount: '',
  },
  brandName: 'Clearpay',
  hasLogo: true,
  hasBorder: true,
};
