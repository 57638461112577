import React, { useEffect, useState } from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { Text } from '@afterpaytouch/core';
import { usePrevious } from '../../utils/hooks';
import { PAYMENT_MODE } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { Money } from '../money';
import { valueIsNotNullOrUndefined } from '../../utils/utils';

export const BubbleMessage = (props) => {
  const { size } = props;
  const { amountDueToday, paymentMode, isCrossBorder, merchantAmount, consumerAmount, exchangeRate } = useSelector((state) => state.paymentReducer);

  const [pufMessage, setPufMessage] = useState('');
  const prevAmountDueToday = usePrevious(amountDueToday);
  const prevPaymentMode = usePrevious(paymentMode);
  const intl = useIntl();

  const getPufMessage = () => {
    const amount = parseFloat(amountDueToday.amount);
    if (amount === 0) return intl.formatMessage({ id: 'bubbleMessage.npuf.text' });

    if (!prevAmountDueToday) return '';

    const prevAmount = parseFloat(prevAmountDueToday.amount);
    if (prevAmount === amount) {
      return '';
    } else if (prevAmount > amount) {
      return intl.formatMessage({ id: 'bubbleMessage.decrease.text' });
    } else if (prevPaymentMode === PAYMENT_MODE.NPUF && paymentMode !== PAYMENT_MODE.NPUF) {
      return intl.formatMessage({ id: 'bubbleMessage.increase.dueToday.text' });
    } else {
      return intl.formatMessage({ id: 'bubbleMessage.increase.text' });
    }
  };

  useEffect(() => {
    amountDueToday && setPufMessage(getPufMessage());
  }, [amountDueToday]);

  return (
    <>
      {pufMessage && (
        <div className={`ap-bubble-message ${(!isCrossBorder || !valueIsNotNullOrUndefined(exchangeRate)) && 'ap-bubble-message--margin'}`}>
          <Text size={size === 'sm' ? 'XS' : 'S'}>
            {pufMessage}
          </Text>
        </div>
      )}
      {(isCrossBorder && valueIsNotNullOrUndefined(exchangeRate)) && (
        <div className="ap-bubble-message__xbt-message">
          <Text size={size === 'sm' ? 'XS' : 'S'}>
            <FormattedMessage
              id="xbt.paymentAmount.message"
              values={{
                merchantAmount: <Money bold amount={merchantAmount} showCurrency />,
                consumerAmount: <Money bold amount={consumerAmount} showCurrency />,
                exchangeRate: <Text renderAs="span" bold>{exchangeRate ?? ''}</Text>,
              }}
            />
          </Text>
        </div>
      )}
      {!pufMessage && <hr className="ap-payment-schedule__divider" />}
    </>
  );
};

BubbleMessage.propTypes = {
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
};
