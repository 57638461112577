import * as local from './local';
import * as remote from './remote';

function checkForError (amount) {
  if (!amount) {
    return 'Please provide the "amount" property.';
  }
  if (typeof amount.amount !== 'string' || typeof amount.currency !== 'string' || isNaN(parseFloat(amount.amount))) {
    return 'Amount must have valid amount and currency fields.';
  }
  if (parseFloat(amount.amount) <= 0) {
    return 'Amount must be positive.';
  }
}

const api = {
  /**
   * get init checkout info for widget
   * @param {object} amount: { amount: string, currency: string }
   * @param {string} token
   * @param {string} locale
   */
  retrieveInitCheckout (amount, token, locale) {
    if (token) {
      return remote.initCheckout(token);
    } else {
      const error = checkForError(amount);
      if (error) {
        throw new Error(error);
      }
      return local.sendUpdate(amount, locale, 0);
    }
  },

  /**
   * update checkout info
   * @param {object} amount: { amount: string, currency: string }
   * @param {string} token
   * @param {string} locale
   */
  updateCheckout (amount, token, locale) {
    const error = checkForError(amount);
    if (error) {
      throw new Error(error);
    }

    return token ? remote.updateCheckout(token, amount) : local.sendUpdate(amount, locale);
  },

  retrieveWidgetConfig (token) {
    return token ? remote.getWidgetConfig(token) : local.getWidgetConfig();
  },
};

export default api;
