const UPDATE_ERROR = 'UPDATE_ERROR';
const UPDATE_ISLOADING = 'UPDATE_ISLOADING';

const initState = {
  hasError: false,
  error: {
    errorCode: null,
  },
  isLoading: true,
};

export default function indicatorReducer (state = initState, action = {}) {
  switch (action.type) {
    case UPDATE_ERROR:
      return {
        ...state,
        hasError: action.hasError,
        error: {
          errorCode: action.payload.errorCode,
          ...(action.payload.errorInfo && { errorInfo: action.payload.errorInfo }),
        },
        isLoading: false,
      };
    case UPDATE_ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        hasError: false,
      };
    default:
      return state;
  }
}

export function setHasError (hasError, error) {
  return {
    hasError,
    payload: error,
    type: UPDATE_ERROR,
  };
}

export function setIsLoading (isLoading) {
  return {
    isLoading,
    type: UPDATE_ISLOADING,
  };
}
